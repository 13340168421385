import { BigNumber, ethers } from 'ethers';

export enum ChainId {
  PolygonMainnet = 137,
  PolygonTestnet = 80002,
}

export const RPC_URLS = {
  [ChainId.PolygonMainnet]: 'https://polygon-rpc.meta-force.space',
  [ChainId.PolygonTestnet]:"https://rpc-amoy.polygon.technology",
};

export const BLOCK_EXPLORERS_URLS = {
  [ChainId.PolygonMainnet]: 'https://polygonscan.com',
  [ChainId.PolygonTestnet]: 'https://amoy.polygonscan.com',
};

export const REGISTRY_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xC9B9d75bAF4c297305E6ba89D298AA4624a458c1",
  [ChainId.PolygonTestnet]: "0x4aec3392c96ea0beefe88c0d6532ad487a9bef9c",
};
export const METACORE_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xde432be2a3a93a83d45ff188cCa49fCE577fA8BF",
  [ChainId.PolygonTestnet]: "0x7e3741b142f3c2243998c8f646d5d1f824fbe0ed",
};
export const METAPAYMENT_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x830017756Ce93b471f90A0502985766C9bb9bAF4",
  [ChainId.PolygonTestnet]: "0xfe0e8d23911075b35210b9c73ba34f4c476b8237",
};
export const CORE_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x107f911Cd80A94f18A32E6bd52E1aC50017d96a3",
  [ChainId.PolygonTestnet]: "0xc099d28a2211ba3eb64d72847ca9afb25f660b90",
};
export const HOLDING_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xD8304e136eB95865D4821f35C8F8C5299c78780c",
  [ChainId.PolygonTestnet]: "0xfa566a2af92a2ed391346982ac57c91e797b6fce",
};
export const METAFORCE_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x415d01fc2266E3c5908e94b8958057936911bed8",
  [ChainId.PolygonTestnet]: "0x1e674fb0a97350fb0b5d71d544934e20db744d08",
};
export const REQUEST_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xd9bF32742c83081f497bc715bDc4AC817cfC3963",
  [ChainId.PolygonTestnet]: "0x968dde456ee06369f64629d857f4e7c04c18bb9f",
};
export const MFS_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xb447aafc36b85e4e1267ec43e6a945ff1edf0555",
  [ChainId.PolygonTestnet]: "0xfd573204c3b02329258c91949b61a4dfaad0f865",
};
export const DAI_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
  [ChainId.PolygonTestnet]: "0x57214ae2ae7dee6067cb65e8abd2fe6d2499a2b6",
};
export const ENERGY_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xecfD832495080753197Bd3E3d9038FC8A5d850Bb",
  [ChainId.PolygonTestnet]: "0x2a71f240c6204f8ca9691f93118a86c1cae7a224",
};
export const HMFS1_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x13e8271B8730c5C2D2E9E38d3534040e2500Cd6e",
  [ChainId.PolygonTestnet]: "0x20a1531BC5Bf727EaA7a4e217FBE9aB350FaECD1",
};
export const HMFS2_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x9aaC7c8b999e447CEfD412eEEA462BDE36f082B4",
  [ChainId.PolygonTestnet]: "0x949086F03A06620DABA0AfD0088968A57c250370",
};
export const HMFS3_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xFb27b03dB7965685Fb59D5e6e0F3048A1EBfE0D8",
  [ChainId.PolygonTestnet]: "0xb78A672F3Aa6e6556AD82Ca92B55AEbB074F7F1d",
};
export const HMFS4_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xb9702E69DA40E2bC692285E01a726Eb41Fd161cc",
  [ChainId.PolygonTestnet]: "0x3eb66613796b047D8B1dDFdDabcFfacd90Ed73A7",
};
export const HMFS5_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x22e752c660Ee5523F1255537B008Ebc64dbaDb0a",
  [ChainId.PolygonTestnet]: "0xBd8EaAB6C038C3D7593eDC1e9CCdA38078A1EB2D",
};
export const HMFS6_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x0c232d69B3FdA089B72488a8c136386f804aBd1e",
  [ChainId.PolygonTestnet]: "0x6D8873f56a56f0Af376091beddDD149f3592e854",
};
export const HMFS7_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xd3C00Da40201eC2e6b111c783F7e2Baa117FE3EE",
  [ChainId.PolygonTestnet]: "0x0C6Dc66f245b8FF1af38fC71692d6676638598D4",
};
export const HMFS8_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xE5036e37562DB9A920c88dF490704Bc40787888A",
  [ChainId.PolygonTestnet]: "0xC16d309521De4ae8a6Bf715b4113332762dfc414",
};
// Геймификация UV
export const QW_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x302fddb97bd8b174acbd52ed64ca750a3706557a",
  [ChainId.PolygonTestnet]: "0xf9C4174d3b3DA5EB8B52DBdD3e193339DcB59028",
};
export const EQN_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x69EC7112353c4f3e551e302fEE4A505c10deeF28",
  [ChainId.PolygonTestnet]: "0xc1c2E740e74Df68Ea27E928870E83F63Bd5F3F76",
};
export const QEN_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xe4f3db05dB311054c46dCEB44e91ff7F5e91B71f",
  [ChainId.PolygonTestnet]: "0xb4A7cCa61541323b7AD875617Ee9757923F21907",
};
export const QRE_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x295f5eCB0C03EF76Cd02F5B6020d4538ccf4d2ad",
  [ChainId.PolygonTestnet]: "0x73Bc182bEf4b5AD261ee652B04025233f7128706",
};

// мини игра UV
export const BUTTON_APP_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x7E2F4352e8464C1515624Fb68827462590dF81C0",
  [ChainId.PolygonTestnet]: "0x9995698ec31551bb9838c197e02475c887ef0bbb",
};

// Bitforce
export const BFE_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x724A7025FeaFFABFd2Fb64Cdb3818DacAC0F542e",
  [ChainId.PolygonTestnet]: "0x724A7025FeaFFABFd2Fb64Cdb3818DacAC0F542e",
};
export const BFC_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xA3634aC0CF3CFE5274A793D8De98d80D84226A39",
  [ChainId.PolygonTestnet]: "0xA3634aC0CF3CFE5274A793D8De98d80D84226A39",
};
export const BFC_PROCESSOR_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x82b039cD056234F90E7D4424954D1a9044178697",
  [ChainId.PolygonTestnet]: "0x82b039cD056234F90E7D4424954D1a9044178697",
};


// Новые контракты
export const NFT_CHIP1_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xa736884e6906c215501cdac091c1783f5b7fc078",
  [ChainId.PolygonTestnet]: "0x041a858b61a0458f87c6b514caeaa6af434604d5",
};
export const NFT_CHIP2_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x386db833c8db141db4b116120b5552c60d98f299",
  [ChainId.PolygonTestnet]: "0xc24019c1a595f40060de25cc38ada7ba30c1337f",
};
export const NFT_CHIP3_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xb8eeb56a491708b2f78fc7425feecd826896a252",
  [ChainId.PolygonTestnet]: "0x0cc602b3abda208f269065b860bd16a10168ed8a",
};
export const NFT_CHIP4_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xe2d759095b7bd0082c0d9bc776c223e5b3d7ec4d",
  [ChainId.PolygonTestnet]: "0x18d75399ea17d33c22137888eaf085a20a51f366",
};
export const NFT_CHIP5_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x6c72093c804cec9148b3fb08fe7ea23b4acee2a4",
  [ChainId.PolygonTestnet]: "0x87a6cd3711be8cf7eabf3bac346be121cc033315",
};
export const NFT_CHIP6_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xae0379ddd83d55ccc35f362a67d5dd234ee2532e",
  [ChainId.PolygonTestnet]: "0x34f39bc4a2bda1dfa33d05477f7eb5e699516ec6",
};
export const NFT_CHIP7_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x8cfe94623856e3a004a77699ece68f92c93c42be",
  [ChainId.PolygonTestnet]: "0x559148063263990beb2a84ea23be88e60303a145",
};
export const NFT_CHIP8_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0xfe14983c9b1d9d7d243261b5a0ad91c0b0f7c45c",
  [ChainId.PolygonTestnet]: "0xc80cd2f3a6dc7f4581f923291507637478e253fe",
};
export const NFT_CHIP9_ADDRESSES = {
  [ChainId.PolygonMainnet]: "0x03b25833d10ff7b191e1b3300661943f13c2d68c",
  [ChainId.PolygonTestnet]: "0xd400cdd5eef2dd3352455a3de9d10fb287392dcd",
};
export const FORCESWAP_ADDRESS = {
  [ChainId.PolygonMainnet]: "0x7e1263c92b997f42d9ef668bbc8ba213bd4ca08a",
  [ChainId.PolygonTestnet]: "0xf073939725c45b078f40d254d0f9363995f9411f",
};
export const EXCHANGE_CONTROLLER_ADDRESS = {
  [ChainId.PolygonMainnet]: "0x079049c225B6eD61c3713D876b2e3498835E3bFa",
  [ChainId.PolygonTestnet]: "0xa7e7a534448449c838056F1F4995E33A608D0562",
};
export const BUYBACK_POOL_ADDRESS = {
  [ChainId.PolygonMainnet]: "0xCC3770599673c361D829e758Ee264F8Ef254c8eD",
  [ChainId.PolygonTestnet]: "0xBe7e916B42BEb4BA9Ae830aA05308413FFc9e936",
};
export const NFT_GRAPHQL_ENDPOINTS = {
  [ChainId.PolygonMainnet]: "https://market.meta-force.space/api/v1/graphql",
  [ChainId.PolygonTestnet]: "https://gamma-3.market.metaforcespace.website/api/v1/graphql",
};
export const URL_SQUID = {
  [ChainId.PolygonMainnet]: "https://uv.meta-force.space/graphql",
  [ChainId.PolygonTestnet]: "https://unite.metaforcespace.website/graphql",
};

export const VESTING_FUND_ADDRESS = {
  [ChainId.PolygonMainnet]: "0x51dDeE7f4865dAb15F5c4B29eeF28429C6B7438f",
  [ChainId.PolygonTestnet]: "0xeEf9790C029A35AECF11275B40255B31A1915Fd3",
};

export const DO_SPACES = {
  URL: "https://metaforce-hub.fra1.digitaloceanspaces.com",
  AVATARS_FOLDER: "/avatars",
  IMG_FORMAT: ".jpg",
};

export const MAX_TIMESTAMP = 8640000000;

export const DECIMALS = 18;

export const FIRST_PACK_PRICE_IN_USD: BigNumber = ethers.utils.parseUnits("50");

export const LEVELS_COUNT = 9;

export const COEFF_INCREASE_COST_PACK_FOR_NEXT_LEVEL = 2;

export const ACTIVATION_COST_RATIO_TO_RENEWAL = 5;

export const HMFS_COUNT = 8;

export const ONE: BigNumber = ethers.utils.parseUnits("1");

export const META_ROLE =
  "0xa9ce9b5120c53e0d8b4fe6e0814a47efbead9ebc4d29ec54571f37f0b61ecf0f";

export const META_FORCE_CONTRACT_ROLE =
  "0x50cf39c8fa39275243850e894fcd4b72000d4f0b08c3de0e36d7f1d1718942da";

export const MINTER_ROLE =
  "0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6";
export const BURNER_ROLE =
  "0x3c11d16cbaffd01df69ce1c404f6340ee057498f5f00246190ea54220576a848";

export const UV_V2_UPGRADE_DT: Date = new Date("2024-04-14T15:12:57Z");

