export const locale = {
  lang: 'hi',
  data: {
    BREADCRUMB: {
      FORCE_SYSTEMS: 'सिस्टम',
      UNITEVERSE_DELTA: 'यूनाइटवर्स डेल्टा',
      FORCECOIN: 'फ़ोर्सकॉइन',
      MATRIX: 'मैट्रिक्स',
      RECENT_ACTIVITY: 'हाल की गतिविधि',
      LORE: 'Legends of Uniteverse',
      BACK: 'Back',
      OVERVIEW: 'खनन',
      TIERS: 'स्तर',
      PROCESSING: 'लहरें',
      BITFORCE: 'BFC',
    },
  },
};
